#file-order {
  & .button-container {
    width: 1440px;
    display: flex;
    min-height: 32px;
    margin-bottom: 14px;
    & .order-date {
      display: flex;
      padding-right: 20px;
      border-right: 1px solid #e6e6e6;
      & .title {
        line-height: 32px;
      }
      & .MuiFormControl-root {
        height: 32px;
        width: 120px;
        margin-left: 20px;
        & .MuiInputBase-root {
          height: 32px;
          background-color: #dedede;
          & input {
            -webkit-text-fill-color: black;
            font-size: 12px;
            margin-top: 4px;
          }
          & .MuiInputAdornment-root {
            display: none;
          }
        }
      }
    }
    & .upload-button-container {
      margin-left: 20px;
      display: flex;
      & .upload-button {
        background-color: #41506e;
        height: 32px;
        box-shadow: none;
      }
    }
    & button,
    a {
      margin-left: 10px;
      width: 102px;
      font-size: 12px;
      height: 32px;
      text-decoration: none;
    }
    button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & button:first-of-type {
      margin-left: auto;
    }
  }
  & .MuiPaper-root {
    box-shadow: none !important;
    & .MuiTableContainer-root {
      & .MuiBox-root {
        box-shadow: none;
      }
      & table thead tr th.MuiTableCell-root {
        border-top: 2px #00182e solid;
        background-color: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
      }
      & tfoot {
        background-color: #f7f7f7;
      }
    }
  }

  & .MuiAutocomplete-root {
    height: 30px;
    & .MuiFormControl-root {
      height: 30px;
      & .MuiInputBase-root {
        height: 30px;
        padding: 0;
        & input {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }
  }

  & .MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
    &:first-of-type {
      width: 40px;
      justify-content: center;
    }
    // 주문수량 input
    &:nth-of-type(13) {
      & input {
        margin-left: 5px;
        margin-right: 8px;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
      }
    }
    & .duplicate-book-button {
      margin-left: 10px;
      min-width: 50px;
      height: 25px;
      color: #236daa;
      background: #ffffff;
      text-align: center;
      padding: 0 12px;
      font-size: 11px;
      border: 1px #c4d5e1 solid;
      transition: all 0.3s ease-in-out;
    }
    .duplicate-book-button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }

  & .MuiTableCell-alignRight {
    padding-right: 15px;
  }

  & .footer-margin-left {
    margin-left: -145px;
    white-space: pre;
  }
}

#duplicate-book-selection-modal-container {
  padding: 42px 34px 34px 34px;
  & .title-container {
    width: 100%;
    padding: 0 0 22px 0;
    margin: 0 0 25px 0;
    border-bottom: 1px #e6e6e6 solid;
    overflow: hidden;
    & span {
      display: block;
      font-size: 28px;
      color: #333;
      line-height: 1.1;
      text-align: left;
    }
    & .closed {
      position: absolute;
      right: 35px;
      top: 27px;
      z-index: 100;
      & a {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../imgs/close-button.png) left top no-repeat;
      }
      & a:hover {
        cursor: pointer;
      }
    }
  }
  & .book-selection-table-container {
    & .MuiTableCell-root {
      font-size: 14px !important;
      font-weight: 500 !important;
    }
  }
}
