#book-store-info {
  & .content-wrapper {
    margin-bottom: 0;
    & .content {
      margin-bottom: 0;
      & h2 {
        padding-bottom: 0;
      }
      & .book-store-info-button-container {
        text-align: right;
        margin-bottom: 10px;
      }
      & .book-store-info-roundBx {
        padding: 40px;
        margin: 0 0 28px 0;
        border: 1px #e5eaeb solid;
        & .tbList {
          vertical-align: middle;
          border-top: 2px #00182e solid;
          text-align: left;
          & table {
            border-collapse: collapse;
            border-spacing: 0;
            table-layout: fixed;
            word-break: break-all;
            width: 100%;
            padding: 0 5px 0 0;
            & th {
              position: relative;
              height: 38px;
              background: #f7f7f7;
              border-bottom: 1px solid #e6e6e6;
              padding: 11px 15px 9px 15px;
              vertical-align: top;
            }
            & td {
              line-height: 1.2;
              border-bottom: 1px #e6e6e6 solid;
              word-break: break-all;
              padding: 11px 10px 9px 15px;
              text-align: left;
            }
          }
        }
      }
    }
  }
  & .inform-text {
    // color: #353535;
    font-size: 18px;
    font-weight: 500;
  }
  & .phone-num-area {
    padding-top: 15px;
    & .phone-num-row {
      margin-bottom: 8px;
      & .phone-num-text {
        margin-left: 10px;
        padding: 3px;
        border-radius: 3px;
        color: #fff;
        background-color: #506971;
      }
    }
  }

  & .footer-container {
    margin-top: 0;
  }
}

#book-store-info-modify-modal {
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  & .modify-request {
    width: 500px;
    height: auto;
    padding: 42px 34px 34px 34px;
    & .title-container {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 0 0;
      overflow: hidden;
      & .title {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      & .closed {
        position: absolute;
        right: 35px;
        top: 27px;
        z-index: 100;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../../imgs/close-button.png) left top no-repeat;
        }
        & a:hover {
          cursor: pointer;
        }
      }
    }
    & .body-container {
      text-align: left;
      vertical-align: middle;
      & table {
        width: 100%;
        padding: 0 5px 0 0;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        word-break: break-all;
        display: table;
        border-top: 1px solid #e6e6e6;

        & tbody {
          & tr {
            & .rBtn {
              border: none;
              box-shadow: none;
              padding: 0;
              overflow: visible;
              cursor: pointer;
              display: inline-block;
              width: 156px;
              height: 40px;
              background: #dc2832;
              text-align: center;
              margin: 0 8px;
              border: 0;
              border-radius: 3px;
              & span {
                font-size: 14px;
                color: #fff;
                font-weight: 500;
                line-height: 40px;
              }
            }
            & th {
              font-weight: 600;
              position: relative;
              height: 38px;
              background: #f7f7f7;
              border-bottom: 1px solid #e6e6e6;
              padding: 11px 15px 9px 15px;
              vertical-align: top;
              & span {
                display: inline-block;
                font-size: 12px;
                color: #333;
                line-height: 1.2;
                font-weight: normal;
                padding: 3px 0 0 0;
              }
            }
            & td {
              vertical-align: middle;
              font-size: 12px;
              color: #666;
              line-height: 1.2;
              border-bottom: 1px #e6e6e6 solid;
              word-break: break-all;
              padding: 11px 10px 9px 15px;
              & input[type="text"] {
                width: 100%;
                height: 32px;
                font-size: 12px;
                color: #333;
                line-height: 32px;
                background: #fff;
                padding: 0 0 0 10px;
                border: 1px #ccc solid;
                margin: -5px 0 -4px;
                box-sizing: border-box;
                vertical-align: middle;
              }
              & p {
                margin: 0;
              }
            }
            & td.center {
              text-align: center !important;
            }
            & td.btn {
              position: relative;
              padding: 10px 95px 9px 15px;
              & .blueBtn {
                position: absolute;
                right: 10px;
                top: 5px;
                border: none;
                box-shadow: none;
                border-radius: 0;
                overflow: visible;
                cursor: pointer;
                min-width: 76px;
                height: 32px;
                background: #41506e;
                text-align: center;
                margin: 0 0 0 10px;
                padding: 0 10px;
                & span {
                  font-size: 12px;
                  color: #fff;
                  font-weight: 600;
                  line-height: 34px;
                }
              }
              & .blueBtn:hover {
                background-color: #236daa;
                & span {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
    & .footer-container {
      position: relative;
      width: 100%;
      min-height: 32px;
      padding: 20px 0 0 0;
      text-align: center;
      margin: 0;
      min-width: 360px;
      & .info-text {
        padding-bottom: 20px;
      }
      & .cBtn {
        overflow: visible;
        cursor: pointer;
        box-shadow: none;
        border-radius: 0;
        min-width: 100px;
        height: 32px;
        background: #fff;
        text-align: center;
        margin: 0 8px;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        & span {
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
          line-height: 32px;
        }
      }
      & .cBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
    }
  }
  & .modify-complete {
    width: 500px;
    height: auto;
    padding: 42px 34px 34px 34px;
    & .title-container {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 0 0;
      border-bottom: 1px solid #e6e6e6;
      overflow: hidden;
      & .title {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      & .closed {
        position: absolute;
        right: 35px;
        top: 27px;
        z-index: 100;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../../imgs/close-button.png) left top no-repeat;
        }
        & a:hover {
          cursor: pointer;
        }
      }
    }
    & .message-container {
      vertical-align: middle;
      margin-top: 20px;
    }

    & .btnArea {
      position: relative;
      width: 100%;
      min-height: 32px;
      padding: 20px 0 0 0;
      text-align: center;
      & .cBtn {
        box-shadow: none;
        border-radius: 0;
        overflow: visible;
        cursor: pointer;
        min-width: 100px;
        height: 32px;
        background: #fff;
        text-align: center;
        margin: 0 8px;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        & span {
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
          line-height: 32px;
        }
      }
      & .cBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
    }
  }
}
