#cart-view {
  & h2 {
    padding-bottom: 15px;
  }
  & .cart-view-top-button-container {
    padding: 0;
    position: relative;
    width: 100%;
    min-height: 32px;
    text-align: center;
    & .left-button-div {
      float: left;
      margin: 0 14px 0 0;
      & button {
        margin: 0 8px 0 0;
        width: 120px;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }

    & .button-div {
      float: right;
      margin: 0 0 14px 0;
      & button {
        margin: 0 0 0 8px;
        width: 120px;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }
  }
  & .cart-view-table-container td input[type="number"],
  .cart-view-table-container td input[type="string"] {
    border: none;
    background: none;
    width: 70px;
  }
  & .cart-view-order-table-container {
    border-top: 2px #00182e solid;
    & table {
      width: 100%;
      // padding: 0 5px 0 0;
      & th {
        width: 250px;
        position: relative;
        height: 38px;
        background: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
      }
      & td {
        width: 400px;
        height: 54px;
        border-bottom: 1px #e6e6e6 solid;
        text-align: right;
        padding: 11px 26px 9px 10px;
        & select {
          width: 100%;
          height: 32px;
        }
        & .right {
          width: 360px;
          text-align: left;
          height: 40px;
        }
        &.left {
          text-align: left;
        }
        &.payment-data {
          width: 238px;
          padding: 11px 10px 9px 10px;
        }
      }
    }
  }
  & .cart-view-bottom-button-container {
    margin: 15px 0 0 0;
    position: relative;
    width: 100%;
    min-height: 32px;
    padding: 20px 0 0 0;
    text-align: center;
    & .button-div {
      float: right;
      margin: 0 0 14px 0;
      & button {
        margin: 0 0 0 8px;
        width: 120px;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }
  }

  .MuiTable-root {
    & .MuiTableHead-root {
      & .MuiTableRow-root {
        & .MuiTableCell-head {
          &:nth-child(1) {
            width: 40px !important;
          }
        }
      }
    }
    & .MuiTableBody-root {
      & .MuiTableRow-root {
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
          &:nth-child(0) {
            width: 40px !important;
          }
          // 주문수량 input
          &:nth-child(13) {
            & input {
              margin-left: 5px;
              margin-right: 10px;
              text-align: right;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    & .MuiTableCell-alignRight {
      padding-right: 10px;
    }
  }
}
