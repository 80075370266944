#modal-container {
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  border-radius: 4px;
  padding: 20px;
  &:has(#notice-modal-container),
  &:has(#sale-stop-modal-container) {
    padding: 0;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 5px 10px #00000050;
  }
}

#sign-out-modal {
  text-align: center;
  background-color: white;
  & .confirm-text {
    color: black;
    margin: 20px;
  }
  & .sign-out-button-group {
    & .sign-out-button {
      margin: 0 10px;
    }
  }
}

#main-test-modal {
  padding: 20px;
}

#modal-book-preview-container {
  padding: 14px;
  width: 400px;
  & .title-container {
    display: flex;
    border-bottom: 1px #e6e6e6 solid;
    padding-bottom: 10px;
    margin-bottom: 20px;
    & .title {
      font-size: 28px;
    }
    & .copy-button {
      height: 24px;
      margin: auto 0 auto auto;
      line-height: 20px;
    }
  }
  & .body {
    display: flex;
    & .image {
      height: 100%;
      margin-left: 10px;
    }
    & .data {
      padding-left: 20px;
      margin-left: 20px;
      & .row {
        display: flex;
        height: 30px;
        & .row-title {
          margin-right: 15px;
        }
        & div:last-of-type {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 160px;
          text-align: left;
        }
      }
    }
  }
}

#address-modal {
  width: 580px;
  & .title-container {
    display: flex;
    border-bottom: 1px #e6e6e6 solid;
    padding: 0 0 22px 0;
    margin: 0 0 25px 0;
    & .title {
      font-size: 28px;
    }
    & a {
      margin-left: auto;
      cursor: pointer;
      display: block;
      width: 40px;
      height: 40px;
      background: url(../imgs/close-button.png) left top no-repeat;
    }
  }
  & .body-container {
    border-top: 2px #00182e solid;
  }
}

// 반품신청 안내문구 모달
#return-guide-modal {
  width: 438px;
  // height: 186px;
  padding: 14px;
  border-width: 0;
  // 제목영역
  & .title-container {
    display: flex;
    padding: 0 0 22px 0;
    margin: 0 0 25px 0;
    border-bottom: 1px #e6e6e6 solid;
    & .title {
      font-size: 28px;
    }
    & a {
      margin-left: auto;
      cursor: pointer;
      display: block;
      width: 40px;
      height: 40px;
      background: url(../imgs/close-button.png) left top no-repeat;
    }
  }
  // 안내문구 영역
  & .body-container {
    text-align: left;
    font-size: 15px;
  }
  // 확인 버튼 영역
  & .footer-container {
    border-top: 1px #e6e6e6 solid;
    padding-top: 30px;
    margin-top: 22px;
    margin-bottom: 0px;
    & button {
      min-width: 100px;
      height: 32px;
      color: #236daa;
      background: #ffffff;
      text-align: center;
      border: 1px #c4d5e1 solid;
      margin-right: 10px;
    }
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }
}
