// Common
@import "./color.scss";
@import "./DataTable.scss";
@import "./Dropzone.scss";
@import "./ErrorPage.scss";
@import "./fonts.scss";
@import "./Footer.scss";
@import "./Header.scss";
@import "./List.scss";
@import "./Main.scss";
@import "./MallCenter.scss";
@import "./Modal.scss";
@import "./MyPage.scss";
@import "./SearchFilter.scss";
@import "./SignIn.scss";
@import "./SignInNew.scss";
@import "./TabMenu.scss";
@import "./Test.scss";

// Deals
@import "./Deals/BooksLedgerView.scss";
@import "./Deals/BookStoreInfo.scss";
@import "./Deals/DealsLedgerView.scss";

// Delivery
@import "./Delivery/DeliveryDealStatus.scss";
@import "./Delivery/DeliveryOrderStatus.scss";
@import "./Delivery/DeliveryProgress.scss";

// History
@import "./History/OrderHistoryView.scss";
@import "./History/OrderHistoryByDailyView.scss";
@import "./History/DirectDeliveryOrderHistoryView.scss";
@import "./History/OutcomingHistoryView.scss";
@import "./History/RefundHistoryView.scss";
@import "./History/ReturnRequest.scss";

// Orders
@import "./Orders/TotalSearch.scss";
@import "./Orders/BestBookOrder.scss";
@import "./Orders/CartView.scss";
@import "./Orders/DirectDeliveryOrderSearch.scss";
@import "./Orders/DirectDeliveryOrderSearchNew.scss";
@import "./Orders/DirectDeliveryOrderFile.scss";
@import "./Orders/FileOrder.scss";
@import "./Orders/NewBookOrder.scss";
@import "./Orders/OrderInquiry.scss";
@import "./Orders/RecommendedOrder.scss";
@import "./Orders/SearchOrder.scss";
@import "./Orders/PaymentSuccess.scss";
@import "./Orders/PaymentFailure.scss";

// Support
@import "./Support/ManageCustomer.scss";
@import "./Support/ManageIncome.scss";
@import "./Support/Notice.scss";
@import "./Support/NoticeDetail.scss";
@import "./Support/OneToOne.scss";
@import "./Support/OneToOneDetail.scss";
@import "./Support/OneToOneInquiry.scss";
@import "./Support/ReturnRequire.scss";

body {
  margin: 0;
  font-family: $NanumBarunGothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  text-align: center;
}

// body::-webkit-scrollbar {
// display: none; /* Chrome, Safari, Opera*/
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

$blue: #0070f3;

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid $blue;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  margin: auto;
}

.spinner-container {
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 100001;
}

.skeleton-container {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 655px;
}

.LazyLoad {
  opacity: 0;
  // transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}

.warning-text {
  color: red;
  height: 24px;
  width: 100%;
}

.blue {
  color: $blue;
}

.page-container {
  min-height: 100vh;
}

.page {
  height: 100%;
}

.content-wrapper {
  width: 100%;
  min-width: 540px;
  margin: 80px 0 90px;
  min-height: 650px;
  display: flex;
}

.content {
  margin: 0 auto 30px;
  width: 1440px;
  & h2 {
    font-size: 18px;
    text-align: left;
    margin: 0;
    padding: 0 0 20px 0;
    font-weight: 400;
    margin: 0 auto;
    & span:first-of-type {
      font-weight: 600;
    }
  }
}

.margin-auto {
  margin: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

tbody {
  font-weight: 400;
}

.option-list {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  cursor: pointer;
  & .option-list-item {
    padding: 0 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
    height: 32px;
    line-height: 32px;
    display: flex;
    cursor: pointer;
    & div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:first-of-type {
        width: 120px;
      }
      &:nth-of-type(3) {
        width: 180px;
      }
      &:nth-of-type(5) {
        width: 80px;
        text-align: right;
      }
      &:nth-of-type(7) {
        width: 70px;
      }
      &:nth-of-type(9) {
        width: 120px;
      }
      &:nth-of-type(2),
      &:nth-of-type(4),
      &:nth-of-type(6),
      &:nth-of-type(8) {
        width: 20px;
        text-align: center;
      }
    }
  }
}

.MuiAutocomplete-popper {
  & .MuiAutocomplete-paper {
    & .MuiAutocomplete-listbox {
      & .Mui-focused {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.search-book-async-input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & input {
    max-width: 130px !important;
  }
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
}

.select-branch-component {
  height: 32px;
  width: 240px;
  margin-left: 20px;
  text-align: left;
}

.account-selection-select {
  width: 500px;
  height: 40px;
  text-align: left;
}

.display-none {
  display: none;
}

// selectBox select list 영역 설정
.MuiPopover-root {
  & .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    border: 1px solid rgba(0, 0, 0, 0.23);
    max-height: 340px;
    max-width: 210px;
    & .MuiButtonBase-root.MuiMenuItem-root {
      font-size: 14px;
      font-weight: 500;
      & .MuiTouchRipple-root {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
