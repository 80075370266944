.dropzone {
  height: 75px;
  width: 100%;
  margin: 35px 0;
  background: #f5f5f5;
  border: 1px #2872af solid;
  overflow: hidden;
}

.input-label {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  line-height: 75px;
  color: #2872af;
}
