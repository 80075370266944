#total-search {
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // |
    & .info-vertical-bar {
      margin-left: 5px;
      margin-right: 5px;
      color: rgba(0, 0, 0, 0.23);
    }

    // 상단 영역
    & .total-header-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 38px;
      margin-bottom: 15px;
      // 통합검색 정보 영역
      & .total-search-info-container {
        display: flex;
        line-height: 38px;
        font-size: 18px;
      }
      // 통합 검색 영역
      & .re-search-container {
        display: flex;
        justify-content: end;
        & .research-keyword-input-field {
          height: 38px;
          width: 250px;
          & input {
            height: 38px;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 500;
          }
        }
        & .search-button {
          height: 38px;
          margin-left: 10px;
          background: #41506e;
          box-shadow: none;
        }
      }
    }

    // 주문관련 버튼 영역
    & .order-button-container {
      display: flex;
      justify-content: end;
      & button {
        margin-left: 10px;
        min-width: 100px;
        height: 38px;
        color: #236daa;
        background: #ffffff;
        text-align: center;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        transition: all 0.3s ease-in-out;
        box-shadow: none;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
      & .selected-book-order-button,
      .selected-book-cart-in-button {
        width: 150px;
      }

      &.top {
        justify-content: space-between;
        & .left-button-container {
          & Button:nth-of-type(1) {
            margin-left: 0;
          }
        }

        & .sort-button {
          width: 120px;

          &.selected {
            color: #ffffff;
            background-color: #236daa;
          }
        }
      }
    }

    // 상단 페이지 네비게이션 영역
    & .top-pagination-container {
      margin-top: 15px;
      margin-bottom: 20px;
    }
    // 하단 페이지 네비게이션 영역
    .bottom-pagination-container {
      margin-top: 20px;
      margin-bottom: 15px;
    }

    // 네비게이션 바 영역 공통
    & .pagination {
      display: flex;
      margin-top: auto;
      & nav {
        margin: auto;
        & .Mui-selected {
          background-color: #292e42;
          color: white;
        }
        & button {
          border: 1px #ddd solid;
          border-radius: 0;
          margin: 0;
        }

        & li:nth-of-type(2) {
          & button {
            margin-right: 6px;
          }
        }
        & li:nth-last-of-type(2) {
          & button {
            margin-left: 6px;
          }
        }
        & button:hover {
          background-color: #292e42;
          color: white;
        }
      }
    }

    // 데이터 영역
    & .body-container {
      width: 1440px;
      // 검색된 도서 목록 영역
      & .searched-book-info-container {
        display: flex;
        flex-wrap: wrap;
        border: 1px #e5eaeb solid;
        padding: 15px;
        min-height: 262px;
        // 검색결과가 없는 경우
        &.no-searched-data {
          justify-content: center;
          line-height: 262px;
        }
        // 도서 정보 영역
        & .book-info-container {
          height: 210px;
          width: 50%;
          display: flex;
          padding: 15px;
          // 도서 이미지 영역
          & .book-img-container {
            width: 130px;
            & img {
              width: 130px;
              border: 1px #e5eaeb solid;
            }
          }
          // 도서 내용 영역
          & .book-contents-container {
            width: 100%;
            height: 180px;
            padding-left: 20px;
            text-align: left;
            font-size: 14px;
            & div {
              height: 28px;
              line-height: 28px;
            }
            // 도서명
            & .book-title {
              display: flex;
              justify-content: space-between;
              font-size: 16px;
              & .book-title-text {
                width: 470px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              & .MuiCheckbox-root {
                width: 28px;
                height: 28px;
              }
            }
            // 도서 출판정보
            & .book-publishing-info {
              display: flex;
              & .publisher-text,
              .writer-text {
                max-width: 128px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            // 도서 가격정보
            & .book-price-info {
              display: flex;
            }
            // 도서 상태정보
            & .book-status-info {
              display: flex;
            }
            // 주문관련
            & .order-book-connainer {
              height: 32px;
              display: flex;
              justify-content: space-between;
              line-height: 32px;
              & .order-qty {
                height: 32px;
                line-height: 32px;
                & .MuiInputBase-root {
                  height: 32px;
                  width: 80px;
                  border: 1px rgba(0, 0, 0, 0.23) solid;
                  margin-left: 5px;
                  margin-right: 5px;
                  & input {
                    font-weight: 500;
                    text-align: right;
                    padding: 5px;
                    height: 22px;
                  }
                }
              }
              // 개별 바로주문, 장바구니 담기 버튼 영역
              & .order-book-button-container {
                display: flex;
                height: 32px;
                & button {
                  margin-left: 10px;
                  min-width: 100px;
                  height: 32px;
                  color: #236daa;
                  background: #ffffff;
                  text-align: center;
                  padding: 0 12px;
                  border: 1px #c4d5e1 solid;
                  transition: all 0.3s ease-in-out;
                  box-shadow: none;
                }
                & button:hover {
                  color: #ffffff;
                  background-color: #236daa;
                }
                & button:disabled {
                  color: rgba(0, 0, 0, 0.26);
                  border: 1px rgba(0, 0, 0, 0.12) solid;
                }
              }
            }
          }
        }
      }
    }
  }
}
