.search-filter-container {
  width: 1440px;
  height: auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  border: 1px #e5eaeb solid;
  background: #fafafa;
  margin: 20px 0;
  & .search-filter-presenter {
    display: flex;
    width: 1046px;
    flex-wrap: wrap;
  }
  & .search-filter {
    display: flex;
    flex-basis: 50%;
    height: 44px;
    padding: 6px;
    & .search-filter-title {
      margin-left: auto;
      width: 100px;
      height: 32px;
      & .search-filter-title-text {
        height: 32px;
        line-height: 32px;
        width: fit-content;
        margin-right: auto;
      }
    }
    & .search-filter-component {
      display: flex;
      width: 400px;
      height: 32px;
      margin-bottom: 10px;
      margin-right: auto;
      & .MuiInputBase-root {
        height: 32px;
        & .MuiInputBase-input {
          height: 32px;
          line-height: 32px;
        }
      }
      & .date-picker {
        height: 32px;
        width: 150px;
        margin: 0 10px;
        background-color: white;
        & input {
          padding-top: 8.5px;
          padding-bottom: 8.5px;
        }
      }
      & .full-range {
        width: 340px;
      }
      & .range-text {
        width: 20px;
        line-height: 40px;
      }
      & .input-field {
        background-color: white;
        margin-left: 10px;
        width: 340px;
        & input {
          padding-top: 8.5px;
          padding-bottom: 8.5px;
        }
      }
      & .select-options {
        width: 340px;
        margin-left: 10px;
        background-color: white;
      }
      & .search-book-async {
        width: 340px;
        margin: 0 10px;
        background-color: white;
        height: 32px;
        & .MuiTextField-root {
          height: 32px;
          & .MuiAutocomplete-inputRoot {
            height: 32px;
            & input {
              padding: 0 4px 0 6px;
              margin-top: -8px;
            }
          }
        }
      }
      & .dropzone {
        width: 340px;
        height: 32px;
        margin: 0 10px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        background-color: white;
        cursor: pointer;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }
    }
  }
  & .divider {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #e6e6e6;
  }
  & .search-button-container {
    padding: 6px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    & .search-button {
      margin-left: auto;
      // margin-right: 84px;
      background: #41506e;
      width: 100px;
      height: 75px;
      box-shadow: none;
    }
  }
  & .mutate-button-container {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 6px;
    margin-top: 15px;
    & button {
      width: 100px;
      height: 32px;
      background-color: white;
    }
    & .save-button {
      margin-left: auto;
      margin-right: 10px;
    }
    & .cancel-button {
      margin-left: 10px;
      margin-right: auto;
    }
  }
}
