#direct-delivery-order-history-view {
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // 헤더 영역
    & .head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      margin-bottom: 14px;
      // 검색 영역
      & .search-container {
        display: flex;
        // 검색 조건 영역
        & .search-option-container {
          height: 32px;
          margin-right: 20px;
          display: flex;
          border-right: 1px #ddd solid;
          // 타이틀 영역 공통
          & .search-filter-title {
            height: 32px;
            & .search-filter-title-text {
              height: 32px;
              line-height: 32px;
              margin-right: 15px;
              text-align: left;
            }
          }
          // 데이터 영역 공통
          & .search-filter-component {
            & .MuiInputBase-root {
              height: 32px;
              & .MuiInputBase-input {
                line-height: 32px;
              }
            }
          }
          // 일자 선택 영역
          & .search-filter-date {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              display: flex;
              height: 32px;
              & .date-picker {
                width: 150px;
                height: 32px;
              }
              // ~
              & .range-text {
                width: 10px;
                margin: 0 10px;
                line-height: 32px;
              }
            }
          }
          // 주문구분 영역
          & .search-filter-select {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              text-align: left;
              & .select-options {
                width: 120px;
              }
            }
          }
          // 도서명 영역
          & .search-filter-text {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              & .search-book-async {
                width: 300px;
                & .MuiInputBase-root {
                  padding: 0px 14px;
                  height: 32px;
                  & input {
                    max-width: 80% !important;
                    padding: 0px;
                  }
                }
              }
            }
            & .MuiAutocomplete-popper {
              width: 800px !important;
              border-radius: 4px;
              & .MuiPaper-root {
                & .option-list-item {
                  width: 800px !important;
                  & div {
                    &:first-of-type {
                      width: 120px;
                    }
                    &:nth-of-type(3) {
                      width: 350px !important;
                    }
                    &:nth-of-type(5) {
                      width: 80px !important;
                    }
                    &:nth-of-type(7) {
                      width: 80px !important;
                    }
                    &:nth-of-type(9) {
                      width: 80px !important;
                    }
                  }
                }
              }
            }
          }
        }
        // 조회 버튼 영역
        & .search-button-container {
          & .search-button {
            width: 76px;
            height: 32px;
            background: #41506e;
            box-shadow: none;
          }
        }
      }
    }
  }

  // 버튼 영역
  & .button-container {
    display: flex;
    float: right;
    & button {
      margin-left: 10px;
      min-width: 100px;
      height: 32px;
      color: #236daa;
      background: #ffffff;
      text-align: center;
      padding: 0 12px;
      border: 1px #c4d5e1 solid;
      transition: all 0.3s ease-in-out;
    }
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }

  // 테이블 영역 공통
  & .table-container {
    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
    }
    & .MuiTableCell-alignRight {
      padding-right: 15px;
    }
    & .footer-margin-left {
      margin-left: -60px;
    }
  }

  // 직배주문내역(상세) 박스 영역
  .order-detail-box {
    margin: 50px auto 0 auto;
    padding: 0 40px 40px;
    border: 1px #e5eaeb solid;
    width: 1440px;

    // 주문번호 영역
    & .detail-head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      max-height: 32px;
      margin-top: 25px;
      margin-bottom: 14px;
      line-height: 32px;
      & .order-info-container {
        display: flex;
        & .order-number,
        .order-date,
        .order-payment {
          margin-right: 10px;
          padding-right: 12px;
          border-right: 1px #ddd solid;
        }
        & .delivery-check-container {
          display: flex;
          height: 32px;
          // 주문구분 영역
          & .search-filter-select {
            display: flex;
            margin-right: 20px;
            // 타이틀 영역 공통
            & .search-filter-title {
              height: 32px;
              & .search-filter-title-text {
                height: 32px;
                line-height: 32px;
                margin-right: 15px;
                text-align: left;
              }
            }
            // 데이터 영역
            & .search-filter-component {
              text-align: left;
              & .select-options {
                height: 32px;
                width: 200px;
              }
            }
          }
          & .delivery-check-button {
            min-width: 50px;
            height: 32px;
            background: #41506e;
            box-shadow: none;
          }
        }
      }
    }
  }
}
