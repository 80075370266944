#order-inquiry {
  & h2 {
    padding-bottom: 15px;
  }
  & .top-button-container {
    padding: 0;
    position: relative;
    width: 100%;
    min-height: 32px;
    text-align: center;
    display: flex;
    & .order-date {
      display: flex;
      padding-right: 20px;
      margin-right: auto;
      & .title {
        line-height: 32px;
      }
      & .MuiFormControl-root {
        height: 32px;
        width: 120px;
        margin-left: 20px;
        & .MuiInputBase-root {
          height: 32px;
          background-color: #dedede;
          & input {
            -webkit-text-fill-color: black;
            font-size: 12px;
            margin-top: 4px;
          }
          & .MuiInputAdornment-root {
            display: none;
          }
        }
      }
    }

    & .button-div {
      float: right;
      margin: 0 0 14px 0;
      & button {
        margin: 0 0 0 8px;
        width: 120px;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }
  }
  & .cart-table-container td input[type="number"],
  .cart-table-container td input[type="string"] {
    border: none;
    background: none;
    width: 70px;
  }
  & .order-table-container {
    border-top: 2px #00182e solid;
    & table {
      width: 100%;
      // padding: 0 5px 0 0;
      & th {
        width: 250px;
        position: relative;
        height: 38px;
        background: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
      }
      & td {
        width: 400px;
        height: 54px;
        border-bottom: 1px #e6e6e6 solid;
        text-align: right;
        padding: 11px 26px 9px 10px;
        & select {
          width: 100%;
          height: 32px;
        }
        & .right {
          width: 360px;
          text-align: left;
          height: 40px;
        }
        &.left {
          text-align: left;
        }
        &.payment-data {
          width: 238px;
          padding: 11px 10px 9px 10px;
        }
      }
    }
  }
  & .bottom-button-container {
    margin: 15px 0 0 0;
    position: relative;
    width: 100%;
    min-height: 32px;
    padding: 20px 0 0 0;
    text-align: center;
    & .button-div {
      float: right;
      margin: 0 0 14px 0;
      & button {
        margin: 0 0 0 8px;
        width: 120px;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }
  }

  .MuiTable-root {
    & .MuiTableBody-root {
      & .MuiTableRow-root {
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
          &:first-of-type {
            text-align: center;
            max-width: 55px !important;
          }
          // 주문수량 input
          &:nth-child(14) {
            max-width: 70px !important;
            & input {
              margin-left: 5px;
              margin-right: 10px;
              text-align: right;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}
