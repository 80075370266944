#recommended-order {
  & .button-container {
    width: 1440px;
    display: flex;
    & .side-menu-top {
      width: 200px;
      display: flex;
      min-height: 32px;
      margin-right: 40px;
      margin-bottom: 14px;
      & .selected-category-text {
        margin: 0 5px;
        line-height: 32px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    & .table-top {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      min-height: 32px;
      margin-bottom: 14px;
      & .date-area {
        display: flex;
        & .search-date {
          display: flex;
          margin-right: 20px;
          & .search-filter-title-text {
            height: 32px;
            line-height: 32px;
            margin-right: 15px;
            text-align: left;
          }
          &.year {
            & .MuiInputBase-root.MuiOutlinedInput-root {
              width: 93px;
            }
          }
          & .MuiInputBase-root {
            height: 32px;
            width: 93px;
            & .MuiInputBase-input {
              padding-top: 0;
              padding-bottom: 0;
              line-height: 32px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }

        & .order-date {
          display: flex;
          padding-right: 20px;
          & .title {
            line-height: 32px;
          }
          & .MuiFormControl-root {
            height: 32px;
            width: 120px;
            margin-left: 20px;
            & .MuiInputBase-root {
              height: 32px;
              background-color: #dedede;
              & input {
                -webkit-text-fill-color: black;
                font-size: 14px;
                font-weight: 500;
              }
              & .MuiInputAdornment-root {
                display: none;
              }
            }
          }
        }

        & .search-button {
          width: 76px;
          height: 32px;
          background: #41506e;
          box-shadow: none;
        }
      }
      & .upload-button-container {
        margin-left: 20px;
        & .upload-button {
          background-color: #41506e;
          height: 32px;
        }
      }
      & button {
        margin-left: 10px;
        width: 102px;
        font-size: 12px;
        height: 32px;
      }
    }
  }
  & .MuiPaper-root {
    box-shadow: none !important;
    & .MuiTableContainer-root {
      & .MuiBox-root {
        box-shadow: none;
      }
      & table thead tr th.MuiTableCell-root {
        border-top: 2px #00182e solid;
        background-color: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
      }
      & tfoot {
        background-color: #f7f7f7;
      }
    }
  }

  & .MuiAutocomplete-root {
    height: 30px;
    & .MuiFormControl-root {
      height: 30px;
      & .MuiInputBase-root {
        height: 30px;
        padding: 0;
        & input {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }
  }
  & .table-container {
    display: flex;
    & .side-menu {
      width: 200px;
      height: 100%;
      margin-right: auto;
      border-top: 2px #00182e solid;
      border-bottom: 2px #00182e solid;
      & .one-depth-end {
        padding: 0 16px !important;
        min-height: 48px;
        line-height: 48px;
        text-align: left;
        border-top: 1px #e3e3e3 solid;
      }
      & .MuiAccordionSummary-root.Mui-expanded {
        min-height: 48px;
      }
      & .MuiAccordionSummary-content {
        margin: 12px 0;
      }
      & .MuiAccordionDetails-root {
        padding: 8px 16px;
        cursor: pointer;
      }
      & .Mui-expanded {
        margin: 0;
      }
    }
    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
      &:first-of-type {
        width: 30px;
        padding-right: 4px;
        padding-left: 4px;
        text-align: center;
        & .MuiCheckbox-root {
          width: 20px;
          height: 20px;
        }
      }
      &:nth-of-type(4) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:nth-of-type(5) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:nth-of-type(8) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:nth-of-type(9) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      // 주문수량 input
      &:nth-child(13) {
        & input {
          margin-left: 5px;
          margin-right: 5px;
          text-align: right;
          font-size: 14px;
          font-weight: 500;
        }
      }
      &:nth-of-type(15) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    & .MuiTableCell-alignRight {
      padding-right: 15px;
    }
  }
  & .footer-margin-left {
    margin-left: -60px;
  }
}
