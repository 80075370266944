#order-history-by-daily-view {
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // 헤더 영역
    & .head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      margin-bottom: 14px;
      // 검색 영역
      & .search-container {
        display: flex;
        // 검색 조건 영역
        & .search-option-container {
          height: 32px;
          margin-right: 20px;
          display: flex;
          border-right: 1px #ddd solid;
          // 타이틀 영역 공통
          & .search-filter-title {
            height: 32px;
            & .search-filter-title-text {
              height: 32px;
              line-height: 32px;
              margin-right: 15px;
              text-align: left;
            }
          }
          // 데이터 영역 공통
          & .search-filter-component {
            & .MuiInputBase-root {
              height: 32px;
              & .MuiInputBase-input {
                line-height: 32px;
              }
            }
          } // 일자 선택 영역
          & .search-filter-date {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              display: flex;
              height: 32px;
              & .date-picker {
                width: 150px;
                height: 32px;
              }
              // ~
              & .range-text {
                width: 10px;
                margin: 0 10px;
                line-height: 32px;
              }
            }
          }
        }
        // 조회 버튼 영역
        & .search-button-container {
          & .search-button {
            width: 76px;
            height: 32px;
            background: #41506e;
            box-shadow: none;
          }
        }
      }
    }

    & .list-table-container {
      & .table-head-container {
        display: flex;
        justify-content: space-between;
        padding: 0;
        position: relative;
        width: auto;
        min-height: 32px;
        max-height: 32px;
        margin-bottom: 14px;
        line-height: 32px;
        // & .title-container {
        //   font-size: 18px;
        // }
      }
      // 버튼 영역
      & .button-container {
        display: flex;
        float: right;
        & button {
          margin-left: 10px;
          min-width: 100px;
          height: 32px;
          color: #236daa;
          background: #ffffff;
          text-align: center;
          padding: 0 12px;
          border: 1px #c4d5e1 solid;
          transition: all 0.3s ease-in-out;
        }
        & button:hover {
          color: #ffffff;
          background-color: #236daa;
        }
      }
      // 테이블 영역 공통
      & .table-container {
        margin-bottom: 30px;
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
        }
        & .MuiTableCell-alignRight {
          padding-right: 15px;
        }
        &.not-out {
          & .MuiTableCell-alignRight {
            padding-right: 35px;
          }
          & .MuiTableCell-root {
            &:nth-of-type(3) {
              padding-left: 25px;
              padding-right: 25px;
            }
            &:nth-of-type(4) {
              padding-left: 25px;
              padding-right: 25px;
            }
            &:nth-of-type(6) {
              padding-left: 25px;
              padding-right: 25px;
            }
          }
        }
      }
    }
    & .footer-margin-daily-order {
      margin-left: -60px;
    }
    & .footer-margin-not-outcoming {
      margin-left: -100px;
    }
  }
}
