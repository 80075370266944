// 반품신청내역 조회
#return-request-view {
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // 헤더 영역
    & .head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      margin-bottom: 14px;
      // 검색 영역
      & .search-container {
        display: flex;
        // 검색 조건 영역
        & .search-option-container {
          display: flex;
          height: 32px;
          border-right: 1px #ddd solid;
          margin-right: 20px;
          // 타이틀 영역 공통
          & .search-filter-title {
            height: 32px;
            & .search-filter-title-text {
              width: 70px;
              height: 32px;
              line-height: 32px;
              margin-right: 10px;
              text-align: left;
            }
          }
          // 데이터 영역 공통
          & .search-filter-component {
            & .MuiInputBase-root {
              height: 32px;
              & .MuiInputBase-input {
                line-height: 32px;
              }
            }
          }
          // 일자 선택 영역
          & .search-filter-date {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              display: flex;
              height: 32px;
              & .date-picker {
                width: 150px;
                height: 32px;
              }
              // ~
              & .range-text {
                width: 10px;
                margin: 0 10px;
                line-height: 32px;
              }
            }
          }
        }
        // 조회 버튼 영역
        & .search-button-container {
          display: flex;
          border-right: 1px #ddd solid;
          margin-right: 20px;
          & .search-button {
            width: 76px;
            height: 32px;
            margin-right: 20px;
            background: #41506e;
            box-shadow: none;
          }
        }
        & .return-button-container {
          & .return-button {
            width: 100px;
            height: 32px;
            margin-right: 20px;
            color: #236daa;
            background: #ffffff;
            border: 1px #c4d5e1 solid;
            box-shadow: none;
          }
          & .return-button:hover {
            color: #ffffff;
            background-color: #236daa;
          }
        }
      }
      // 버튼 영역
      & .button-container {
        display: flex;
        float: right;
        & button {
          margin-left: 10px;
          min-width: 100px;
          height: 32px;
          color: #236daa;
          background: #ffffff;
          text-align: center;
          padding: 0 12px;
          border: 1px #c4d5e1 solid;
          transition: all 0.3s ease-in-out;
        }
        & button:hover {
          color: #ffffff;
          background-color: #236daa;
        }
      }
    }
  }

  // 테이블 영역 공통
  & .table-container {
    margin-bottom: 30px;
    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
    }
    & .MuiTableCell-alignRight {
      padding-right: 15px;
    }
  }

  & .detail-head-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    position: relative;
    width: auto;
    min-height: 32px;
    padding-bottom: 14px;
    line-height: 32px;
    // 버튼 영역
    & .button-container {
      display: flex;
      float: right;
      & button {
        margin-left: 10px;
        min-width: 100px;
        height: 32px;
        color: #236daa;
        background: #ffffff;
        text-align: center;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        transition: all 0.3s ease-in-out;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
    }
  }

  & .footer-container {
    margin: 0 0 32px 0;
  }
}

// 반품신청 입력
#return-request-form {
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // 헤더 영역
    & .head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      margin-bottom: 14px;
      // 입력 영역
      & .input-container {
        display: flex;
        // 입력 조건 영역
        & .input-option-container {
          height: 32px;
          display: flex;
          margin-right: 20px;
          border-right: 1px #ddd solid;
          // 타이틀 영역 공통
          & .input-title {
            height: 32px;
            & .input-title-text {
              width: 70px;
              height: 32px;
              line-height: 32px;
              margin-right: 10px;
              text-align: left;
            }
          }
          // 입력 영역 공통
          & .input-component {
            & .MuiInputBase-root {
              height: 32px;
              & .MuiInputBase-input {
                line-height: 32px;
                padding-top: 0px;
                padding-bottom: 0px;
              }
            }
          }
          // 일자 입력 영역
          & .input-date {
            display: flex;
            margin-right: 20px;
            // 입력 영역
            & .input-component {
              display: flex;
              width: 130px;
              height: 32px;
              background-color: #dedede;
              & input {
                -webkit-text-fill-color: black;
              }
              & .MuiInputAdornment-root {
                display: none;
              }
            }
          }
          // 담당자 정보 입력 영역
          & .input-manager-info {
            display: flex;
            margin-right: 20px;
            // 입력 타이틀 영역
            & .input-title {
              display: flex;
              // 아이콘 영역
              & .input-title-icon {
                width: 20px;
                padding-right: 5px;
                & .check {
                  position: absolute;
                  top: 9px;
                  transform: rotate(45deg);
                  height: 10px;
                  width: 5px;
                  border: 0 #ff0000 solid;
                  border-width: 0 2px 2px 0;
                  margin: 0;
                  padding: 0;
                }
              }
              // 타이틀
              & .input-title-text {
                width: 50px;
              }
            }
            // 입력 영역
            & .input-component {
              display: flex;
              // 담당자 이름 입력
              & .input-manager-name {
                padding-right: 10px;
              }
              & .MuiFormControl-root {
                & .MuiInputBase-root {
                  width: 150px;
                  height: 32px;
                  & input {
                    height: 32px;
                  }
                }
              }
            }
          }
        }
        // 일괄등록 버튼 영역
        & .upload-button-container {
          display: flex;
          // 일괄등록 버튼
          & .upload-button {
            height: 32px;
            margin-right: 20px;
            background: #41506e;
            box-shadow: none;
          }
        }
        // 양식 받기 버튼
        & .form-down {
          display: flex;
          & a {
            box-shadow: none;
            text-decoration: none;
            & button {
              height: 32px;
            }
            & button:hover {
              color: #ffffff;
              background-color: #236daa;
            }
          }
        }
      }
    }
    // 버튼 영역
    & .button-container {
      display: flex;
      float: right;
      & button {
        margin-left: 10px;
        min-width: 100px;
        height: 32px;
        color: #236daa;
        background: #ffffff;
        text-align: center;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        transition: all 0.3s ease-in-out;
        box-shadow: none;
      }
      & button:hover {
        color: #ffffff;
        background-color: #236daa;
      }
      & button:disabled {
        color: rgba(0, 0, 0, 0.26);
        border: 1px rgba(0, 0, 0, 0.12) solid;
      }
    }
  }

  // 테이블 영역
  & .table-container {
    padding-bottom: 14px;
    & .MuiAutocomplete-root {
      height: 30px;
      & .MuiFormControl-root {
        height: 30px;
        & .MuiInputBase-root {
          height: 30px;
          padding: 0;
          & input {
            margin-top: -3px;
            margin-left: 5px;
          }
        }
      }
    }
    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
      &:first-of-type {
        justify-content: center;
        width: 25px;
      }
      // 도서검색 input
      &:nth-child(4) {
        & input {
          font-size: 14px;
          font-weight: 500;
          max-width: 80% !important;
        }
      }
      &:nth-of-type(9) {
        & input {
          text-align: right;
          padding-right: 5px;
        }
      }
    }
    & .MuiTableCell-alignRight {
      padding-right: 15px;
    }
    // 도서검색 자동완성 리스트 width
    & .MuiAutocomplete-popper {
      width: 800px !important;
      border-radius: 4px;
      & .MuiPaper-root {
        border: 1px solid rgba(0, 0, 0, 0.23);
        & .option-list-item {
          width: 800px !important;
          & div {
            &:first-of-type {
              width: 120px;
            }
            &:nth-of-type(3) {
              width: 350px !important;
            }
            &:nth-of-type(5) {
              width: 80px !important;
            }
            &:nth-of-type(7) {
              width: 80px !important;
            }
            &:nth-of-type(9) {
              width: 80px !important;
            }
          }
        }
      }
    }
  }

  // 테이블 하단 버튼 영역
  & .table-footer-button-container {
    display: flex;
    justify-content: right;
    position: relative;
    width: auto;
    min-height: 32px;
    // 버튼 공통
    & button {
      height: 32px;
      text-align: center;
      color: #236daa;
      background: #ffffff;
      border: 1px #c4d5e1 solid;
      transition: all 0.3s ease-in-out;
      box-shadow: none;
    }
    // 버튼 호버 공통
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & button:disabled {
      color: rgba(0, 0, 0, 0.26);
      border: 1px rgba(0, 0, 0, 0.12) solid;
    }
    // 완료 버튼 영역
    & .submit-container {
      & button {
        width: 100px;
        margin-left: 10px;
      }
    }
    & .row-control-container {
      & button {
        width: 100px;
        margin-right: 10px;
      }
    }
  }
}

// PG결제사용 반품신청
#return-request-form-for-pg {
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // 헤더 영역
    & .head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: auto;
      min-height: 32px;
      margin-bottom: 14px;
      // 검색 영역
      & .search-container {
        display: flex;
        // 검색 조건 영역
        & .search-option-container {
          height: 32px;
          margin-right: 20px;
          display: flex;
          border-right: 1px #ddd solid;
          // 타이틀 영역 공통
          & .search-filter-title {
            height: 32px;
            & .search-filter-title-text {
              height: 32px;
              line-height: 32px;
              margin-right: 15px;
              text-align: left;
            }
          }
          // 데이터 영역 공통
          & .search-filter-component {
            & .MuiInputBase-root {
              height: 32px;
              & .MuiInputBase-input {
                line-height: 32px;
              }
            }
          }
          // 일자 선택 영역
          & .search-filter-date {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              display: flex;
              height: 32px;
              & .date-picker {
                width: 150px;
                height: 32px;
              }
              // ~
              & .range-text {
                width: 10px;
                margin: 0 10px;
                line-height: 32px;
              }
            }
          }
          // 주문구분 영역
          & .search-filter-select {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              text-align: left;
              & .select-options {
                width: 120px;
              }
            }
          }
          // 도서명 영역
          & .search-filter-text {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              & .search-book-async {
                width: 300px;
                & .MuiInputBase-root {
                  padding: 0px 14px;
                  & input {
                    height: 32px;
                    max-width: 80% !important;
                    padding: 0px;
                  }
                }
              }
            }
          }
        }
        // 조회 버튼 영역
        & .search-button-container {
          & .search-button {
            width: 76px;
            height: 32px;
            background: #41506e;
            box-shadow: none;
          }
        }
      }
      // 버튼 영역
      // & .button-container {
      //   display: flex;
      //   float: right;
      //   & button {
      //     margin-left: 10px;
      //     min-width: 100px;
      //     height: 32px;
      //     color: #236daa;
      //     background: #ffffff;
      //     text-align: center;
      //     padding: 0 12px;
      //     border: 1px #c4d5e1 solid;
      //     transition: all 0.3s ease-in-out;
      //   }
      //   & button:hover {
      //     color: #ffffff;
      //     background-color: #236daa;
      //   }
      // }
    }
  }

  // 테이블 영역 공통
  & .table-container {
    margin-bottom: 30px;

    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
      &:first-of-type {
        width: 25px;
        justify-content: center;
        text-align: center;
      }
      &.MuiTableCell-body {
        &:first-of-type {
          padding: 0 !important;
        }
      }
    }

    & .MuiTableCell-alignRight {
      padding-right: 15px;
    }
  }

  // 출고내역(상세) 박스 영역
  .order-detail-box {
    margin: 50px auto 0 auto;
    padding: 0 40px 40px;
    border: 1px #e5eaeb solid;
    width: 1440px;

    // 출고번호 영역
    & .detail-head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      max-height: 32px;
      margin-top: 25px;
      margin-bottom: 14px;
      line-height: 32px;

      // 입력 영역 공통
      & .input-component {
        & .MuiInputBase-root {
          height: 32px;
          & .MuiInputBase-input {
            line-height: 32px;
            padding-top: 0px;
            padding-bottom: 0px;
          }
        }
      }

      // 주문정보 영역
      & .order-info-container {
        display: flex;
        & .order-number {
          margin-right: 10px;
          padding-right: 12px;
          border-right: 1px #ddd solid;
        }
        & .delivery-check-container {
          display: flex;
          height: 32px;
          // 주문구분 영역
          & .search-filter-select {
            display: flex;
            margin-right: 20px;
            // 타이틀 영역 공통
            & .search-filter-title {
              height: 32px;
              & .search-filter-title-text {
                height: 32px;
                line-height: 32px;
                margin-right: 15px;
                text-align: left;
              }
            }
            // 데이터 영역
            & .search-filter-component {
              text-align: left;
              & .select-options {
                height: 32px;
                width: 200px;
              }
            }
          }
          & .delivery-check-button {
            min-width: 50px;
            height: 32px;
            background: #41506e;
            box-shadow: none;
          }
        }
        // 일자 입력 영역
        & .input-date {
          display: flex;
          margin-right: 20px;
          // 타이틀
          & .input-title-text {
            margin-right: 10px;
          }
          // 입력 영역
          & .input-component {
            display: flex;
            width: 130px;
            height: 32px;
            background-color: #dedede;
            & input {
              -webkit-text-fill-color: black;
            }
            & .MuiInputAdornment-root {
              display: none;
            }
          }
        }
        // 담당자 정보 입력 영역
        & .input-manager-info {
          display: flex;
          margin-right: 20px;
          // 입력 타이틀 영역
          & .input-title {
            display: flex;
            // 아이콘 영역
            & .input-title-icon {
              width: 20px;
              padding-right: 5px;
              & .check {
                position: absolute;
                top: 9px;
                transform: rotate(45deg);
                height: 10px;
                width: 5px;
                border: 0 #ff0000 solid;
                border-width: 0 2px 2px 0;
                margin: 0;
                padding: 0;
              }
            }
            // 타이틀
            & .input-title-text {
              margin-right: 10px;
            }
          }
          // 입력 영역
          & .input-component {
            display: flex;
            // 담당자 이름 입력
            & .input-manager-name {
              padding-right: 10px;
            }
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                width: 150px;
                height: 32px;
                & input {
                  height: 32px;
                  font-weight: 500;
                  &:disabled {
                    background: #f1f1f1;
                  }
                }
              }
            }
          }
        }
        // 반품사유 영역
        & .input-return-desc {
          display: flex;
          margin-right: 20px;
          & .return-desc-title {
            margin-right: 10px;
            line-height: 32px;
          }
          & .return-desc-component {
            text-align: left;
            & .MuiInputBase-root {
              width: 150px;
              height: 32px;
              font-size: 14px;
              font-weight: 500;
              &.Mui-disabled {
                background: #f1f1f1;
              }
              & .MuiInputBase-input {
                line-height: 32px;
              }
            }
          }
          // & .input-field {
          //   width: 250px;
          //   & input {
          //     height: 32px;
          //     padding-top: 0;
          //     padding-bottom: 0;
          //     &:disabled {
          //       background: #f1f1f1;
          //     }
          //   }
          // }
        }
      }
    }

    & .table-container {
      margin-bottom: 14px;

      & .MuiTableBody-root {
        & .MuiTableRow-root {
          & .MuiTableCell-body {
            &:nth-child(12) {
              padding-right: 8px;
            }
            & .MuiInputBase-root.Mui-disabled {
              background: #f1f1f1;
            }
          }
        }
      }

      & .banp-qty-input-box {
        & input {
          text-align: right;
          padding: 4px 6px 4px 4px;
        }
      }
    }

    // 상세 하단 영역
    & .detail-footer-container {
      display: flex;
      justify-content: end;
      // 버튼 영역
      & .button-container {
        display: flex;
        // justify-content: end;
        // float: right;
        & button {
          margin-left: 10px;
          min-width: 100px;
          height: 32px;
          color: #236daa;
          background: #ffffff;
          text-align: center;
          padding: 0 12px;
          border: 1px #c4d5e1 solid;
          // transition: all 0.3s ease-in-out;
          box-shadow: none;
          &:hover {
            color: #ffffff;
            background-color: #236daa;
          }
          &:disabled {
            color: rgba(0, 0, 0, 0.26);
            border: 1px rgba(0, 0, 0, 0.12) solid;
          }
        }
      }
    }
  }
}
