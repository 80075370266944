#main-page {
  & .main-container {
    margin: auto;
    display: flex;
    justify-content: space-between;
    & .left-container,
    & .right-container {
      width: 700px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 10px;
      margin-top: 20px;
      & .notice-list {
        margin-bottom: 20px;
        & .data-row {
          line-height: 25px;
          & a.row.title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          & div.row {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    & .left-container {
      margin-left: auto;
      & .notice-list {
        margin-left: auto;
      }
    }
    & .right-container {
      margin-right: auto;
    }
    & .list-title {
      width: 100%;
      font-size: 18px;
      display: flex;
      color: #444444;
      margin-bottom: 7px;
      & .more-button {
        margin-left: auto;
        height: 24px;
        font-size: 12px;
        cursor: pointer;
      }
    }
    & .list-body {
      text-align: center;
      vertical-align: middle;
      border-top: 2px #00182e solid;
      & .list-header {
        display: flex;
        & .column {
          height: 38px;
          background: #f7f7f7;
          border-bottom: 1px solid #e6e6e6;
          line-height: 38px;
          font-size: 12px;
        }
      }
      & .list-content {
        // font-weight: 200;
        font-size: 14px;
        font-weight: 500;
        & a {
          text-decoration: none;
          color: #666;
        }
        & a:hover {
          text-decoration: underline;
        }
        & .no-data {
          margin: auto;
          height: 57px;
          line-height: 57px;
        }
        & .data-row {
          display: flex;
          border-bottom: 1px solid #e6e6e6;
          & .row {
            padding: 8px 5px;
          }
          & .title {
            text-align: left;
            padding-left: 30px;
          }
        }
      }
    }
    & .one-to-one-container {
      padding: 20px 40px 40px;
      border: 1px #e5eaeb solid;
      width: 670px;
      & .inquiry-list {
        width: 100%;
        border-bottom: 1px #e4ebf1 solid;
        border-top: 2px #00182e solid;
        display: flex;
        & .inquiry-row {
          height: 40px;
          line-height: 40px;
          // font-size: 12px;
          // font-weight: 200;
          font-size: 14px;
          font-weight: 500;
          flex-basis: 50%;
          display: flex;
          justify-content: space-around;
        }
        & .inquiry-row:first-of-type {
          border-right: 1px #e4ebf1 solid;
        }
      }
    }
    & .deal-status-container {
      padding: 20px 40px 40px;
      border: 1px #e5eaeb solid;
      width: 670px;
      margin-left: auto;
      & .list-body {
        margin-bottom: 20px;
        & .list-header {
          & .column {
            width: 118.25px;
            & span {
              display: inline-block;
              width: 18px;
              height: 18px;
              text-decoration: none;
              // font-size: 12px;
              // font-weight: 400;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              color: #fff;
              background: #5f5f5f;
              border-radius: 100%;
              margin: 0 0 0 3px;
              cursor: pointer;
            }
            & .hover-text {
              line-height: 16px;
              text-align: left;
              margin-top: -105px;
              padding: 5px;
              background-color: white;
              border: 1px solid #e5eaeb;
              z-index: 100;
              color: #888;
              display: none;
            }
            & .active {
              display: block;
            }
          }
          & .column:first-of-type {
            width: 115px;
          }
        }
        & .data-row {
          display: flex;
          border-bottom: 1px solid #e6e6e6;
          & .row {
            padding: 8px 5px;
            width: 118.25px;
            // font-size: 12px;
            // font-weight: 200;
            font-size: 14px;
            font-weight: 500;
          }
          & .row:first-of-type {
            width: 115px;
          }
          & .title {
            text-align: left;
            padding-left: 30px;
          }
        }
      }
    }
    & .test-button-container {
      margin-top: 30px;
      & button {
        margin: 20px;
      }
    }
  }
}

#notice-modal-container {
  // cursor: pointer;
  // width: 400px;
  // height: 448px;
  & .notice-img {
    border-radius: 8px;
    & img {
      border-radius: 8px 8px 0 0;
    }
  }
  & .btnArea {
    display: flex;
    background-color: #444444;
    border-radius: 0 0 8px 8px;
    height: 48px;
    & .cBtn {
      line-height: 48px;
      align-items: center;
      flex-basis: 50%;
      color: white;
      &:first-of-type {
        border-right: 1px solid #cccccc;
      }
    }
  }
}

#sale-stop-modal-container {
  cursor: pointer;
  width: 500px;
  height: 748px;
  & .notice-img {
    border-radius: 8px;
    & img {
      border-radius: 8px 8px 0 0;
    }
  }
  & .btnArea {
    display: flex;
    background-color: #444444;
    border-radius: 0 0 8px 8px;
    height: 48px;
    & .cBtn {
      line-height: 48px;
      align-items: center;
      flex-basis: 50%;
      color: white;
      &:first-of-type {
        border-right: 1px solid #cccccc;
      }
    }
  }
}

#main-modal-container {
  width: 400px;
  height: auto;
  & .innerBox {
    padding: 42px 34px 34px 34px;
    & #guHead {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 25px 0;
      border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & span {
        display: block;
        font-size: 18px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      // & .guClosed {
      //   float: right;
      //   & a {
      //     display: block;
      //     width: 40px;
      //     height: 40px;
      //     size: 50%;
      //     background: url(../imgs/close-button.png) left top no-repeat;
      //     cursor: pointer;
      //   }
      // }
    }
    & .btnArea {
      position: relative;
      width: 100%;
      min-height: 32px;
      text-align: center;
      & .MuiFormGroup-root {
        align-items: center;
        margin-bottom: 5px;
      }
      & .cBtn {
        box-shadow: none;
        border-radius: 0;
        overflow: visible;
        cursor: pointer;
        min-width: 100px;
        height: 32px;
        background: #fff;
        text-align: center;
        margin: 0 8px;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        & span {
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
          line-height: 32px;
        }
      }
      & .cBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
    }
  }
}
