$NanumBarunGothic: "NanumBarunGothic", sans-serif;

@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 400;
  src: local("NanumBarunGothic"),
    url("../fonts/NanumBarunGothic.otf") format("otf"),
    url("../fonts/NanumBarunGothic.woff") format("woff"),
    url("../fonts/NanumBarunGothic.woff2") format("woff2");
  font-display: swap;
  // src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWeb.eot");
  // src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWeb.eot?#iefix")
  //     format("embedded-opentype"),
  //   url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWeb.woff")
  //     format("woff"),
  //   url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWeb.ttf")
  //     format("truetype");
}

@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 200;
  src: local("NanumBarunGothicLight"),
    url("../fonts/NanumBarunGothicLight.otf") format("otf"),
    url("../fonts/NanumBarunGothicLight.woff") format("woff"),
    url("../fonts/NanumBarunGothicLight.woff2") format("woff2");
  font-display: swap;
  // src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebLight.eot");
  // src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebLight.eot?#iefix")
  //     format("embedded-opentype"),
  //   url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebLight.woff")
  //     format("woff"),
  //   url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebLight.ttf")
  //     format("truetype");
}

@font-face {
  font-family: "NanumBarunGothic";
  font-style: normal;
  font-weight: 600;
  src: local("NanumBarunGothicBold"),
    url("../fonts/NanumBarunGothicBold.otf") format("otf"),
    url("../fonts/NanumBarunGothicBold.woff") format("woff"),
    url("../fonts/NanumBarunGothicBold.woff2") format("woff2");
  font-display: swap;
  // src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWebBold.eot");
  // src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWebBold.eot?#iefix")
  //     format("embedded-opentype"),
  //   url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWebBold.woff")
  //     format("woff"),
  //   url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothic/NanumBarunGothicWebBold.ttf")
  //     format("truetype");
}

// @font-face {
//   font-family: "NanumBarunGothic";
//   font-style: normal;
//   font-weight: 100;
//   src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebUltraLight.eot");
//   src: url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebUltraLight.eot?#iefix")
//       format("embedded-opentype"),
//     url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebUltraLight.woff")
//       format("woff"),
//     url("https://hangeul.pstatic.net/hangeul_static/webfont/NanumBarunGothicL/NanumBarunGothicWebUltraLight.ttf")
//       format("truetype");
// }
