#new-book-order {
  & .button-container {
    width: 1440px;
    display: flex;
    min-height: 32px;
    margin-bottom: 14px;
    & .order-date {
      display: flex;
      padding-right: 20px;
      border-right: 1px solid #e6e6e6;
      & .title {
        line-height: 32px;
      }
      & .MuiFormControl-root {
        height: 32px;
        width: 120px;
        margin-left: 20px;
        & .MuiInputBase-root {
          height: 32px;
          background-color: #dedede;
          & input {
            -webkit-text-fill-color: black;
            font-size: 12px;
            margin-top: 4px;
          }
          & .MuiInputAdornment-root {
            display: none;
          }
        }
      }
    }
    & .get-new-book-button-container {
      margin-left: 20px;
      & .get-new-book-button {
        background-color: #41506e;
        height: 32px;
        color: white;
      }
    }
    & button {
      margin-left: 10px;
      width: 102px;
      font-size: 12px;
      height: 32px;
    }
    & button:first-of-type {
      margin-left: auto;
    }
  }
  & .MuiPaper-root {
    box-shadow: none !important;
    & .MuiTableContainer-root {
      & .MuiBox-root {
        box-shadow: none;
      }
      & table thead tr th.MuiTableCell-root {
        border-top: 2px #00182e solid;
        background-color: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
      }
      & tfoot {
        background-color: #f7f7f7;
      }
    }
  }
  & .MuiAutocomplete-root {
    height: 30px;
    & .MuiFormControl-root {
      height: 30px;
      & .MuiInputBase-root {
        height: 30px;
        padding: 0;
        & input {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }
  }

  // & .MuiTableCell-head {
  //   &:first-of-type {
  //     padding-right: -50px;
  //     max-width: 20px !important;
  //     & .MuiBox-root.css-1vxrw8t {
  //       max-width: 20px !important;
  //       & .MuiBox-root.css-g8gl8q {
  //         max-width: 20px !important;
  //         margin-left: 0px !important;
  //         margin-right: 0px !important;
  //         & .MuiCheckbox-root {
  //           width: 20px;
  //           height: 20px;
  //         }
  //       }
  //     }
  //   }
  // }

  & .MuiTableCell-root {
    max-width: 30px !important;
    font-size: 14px;
    font-weight: 500;
    &:first-of-type {
      // max-width: 30px !important;
      text-align: center;
      & .MuiCheckbox-root {
        width: 20px;
        height: 20px;
      }
    }
    // 주문수량 input
    &:nth-child(14) {
      & input {
        margin-left: 5px;
        margin-right: 5px;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  & .MuiTableCell-alignRight {
    padding-right: 15px;
  }

  & .footer-margin-left {
    margin-left: -170px;
  }
}

#new-book-search-modal {
  padding: 42px 34px 34px 34px;
  // height: 700px;
  & .title-container {
    width: 100%;
    padding: 0 0 22px 0;
    margin: 0 0 25px 0;
    border-bottom: 1px #e6e6e6 solid;
    overflow: hidden;
    & span {
      display: block;
      font-size: 28px;
      color: #333;
      line-height: 1.1;
      text-align: left;
    }
    & .closed {
      position: absolute;
      right: 35px;
      top: 27px;
      z-index: 100;
      & a {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../imgs/close-button.png) left top no-repeat;
      }
      & a:hover {
        cursor: pointer;
      }
    }
  }
  // 헤더 영역
  & .head-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    position: relative;
    width: auto;
    min-height: 32px;
    margin-bottom: 14px;
    // 검색 영역
    & .search-container {
      display: flex;
      // 검색 조건 영역
      & .search-option-container {
        height: 32px;
        margin-right: 20px;
        display: flex;
        border-right: 1px #ddd solid;
        // 타이틀 영역 공통
        & .search-filter-title {
          height: 32px;
          & .search-filter-title-text {
            height: 32px;
            line-height: 32px;
            margin-right: 15px;
            text-align: left;
          }
        }
        // 데이터 영역 공통
        & .search-filter-component {
          &.searchType {
            & .MuiInputBase-root.MuiOutlinedInput-root {
              width: 100px;
            }
          }
          // &.year {
          //   & .MuiInputBase-root.MuiOutlinedInput-root {
          //     width: 100px;
          //   }
          // }
          // &.month {
          //   & .MuiInputBase-root.MuiOutlinedInput-root {
          //     width: 80px;
          //   }
          // }
          // &.week {
          //   & .MuiInputBase-root.MuiOutlinedInput-root {
          //     width: 80px;
          //   }
          // }
          &.class {
            & .MuiInputBase-root.MuiOutlinedInput-root {
              width: 230px;
            }
          }
          &.book-title {
            & .MuiInputBase-root.MuiOutlinedInput-root {
              width: 230px;
            }
          }
          & .MuiInputBase-root {
            height: 32px;
            & .MuiInputBase-input {
              line-height: 32px;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        // 일자 선택 영역
        & .search-filter-date {
          display: flex;
          margin-right: 20px;
          // 데이터 영역
          & .search-filter-component {
            display: flex;
            height: 32px;
            & .date-picker {
              width: 150px;
              height: 32px;
            }
            // ~
            & .range-text {
              width: 10px;
              margin: 0 10px;
              line-height: 32px;
            }
          }
        }
        // 수주등록구분 영역
        & .search-filter-select {
          display: flex;
          margin-right: 20px;
          // 데이터 영역
          & .search-filter-component {
            text-align: left;
            & .select-options {
              width: 150px;
            }
          }
        }
      }
      // 조회 버튼 영역
      & .search-button-container {
        & .search-button {
          width: 76px;
          height: 32px;
          background: #41506e;
          color: #ffffff;
          box-shadow: none;
        }
      }
    }
  }
  & .table-container {
    min-height: 405.828px;
    & .MuiTable-root {
      & .MuiTypography-root {
        min-height: 368.33px;
      }
      // & .MuiTableBody-root {
      // & .MuiTableRow-root {
      //   height: 55.28px;
      // }
      // }
      & .MuiTableCell-root {
        font-size: 14px;
        font-weight: 500;
        &:first-of-type {
          width: 30px;
          padding-top: 0;
          text-align: center;
        }
        &:nth-of-type(4) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(5) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(8) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(9) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:nth-of-type(13) {
          & div {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  & .footer-container {
    display: flex;
    justify-content: right;
    padding: 0;
    position: relative;
    width: auto;
    min-height: 32px;
    margin-top: 14px;
    margin-bottom: 0;
    border-top: 0;

    & .list-in-button {
      margin-left: 10px;
      min-width: 100px;
      height: 32px;
      color: #236daa;
      background: #ffffff;
      text-align: center;
      padding: 0 12px;
      border: 1px #c4d5e1 solid;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: #ffffff;
        background-color: #236daa;
      }
      &:disabled {
        color: rgba(0, 0, 0, 0.26);
        border: 1px rgba(0, 0, 0, 0.12) solid;
      }
    }
  }
}
