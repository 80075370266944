#delivery-progress {
  & .content-wrapper {
    margin: 80px 0 0 0;
    & .delivery-progress-head-container {
      padding: 0;
      position: relative;
      width: 100%;
      min-height: 32px;
      text-align: center;
      & .search-container {
        float: left;
        margin: 0 0 14px 0;
        width: 100px;
        & .search-filter-container {
          float: left;
          background: none;
          border: none;
          margin: 0 0 0 0;
          padding: 0 0 0 0;
          & .search-filter-presenter {
            flex-wrap: nowrap;
            width: 980px;
            & .search-filter {
              position: relative;
              float: left;
              margin: 0 20px 0 0;
              & .search-filter-title {
                margin: 0 0 0 0;
                width: 60px;
              }
            }
            & .search-filter:nth-child(1) .search-filter-component {
              width: 200px;
            }
            & .search-filter:nth-child(1) {
              & .closed {
                position: absolute;
                right: 0;
                top: 12px;
                & a {
                  display: block;
                  width: 40px;
                  height: 40px;
                  background: url(../../imgs/close-button.png) left top
                    no-repeat;
                  background-size: 50%;
                  opacity: 0.5;
                }
                & a:hover {
                  cursor: pointer;
                }
              }
            }
            & .search-filter:nth-child(2) .search-filter-component {
              width: 300px;
              & .search-book-async {
                & .MuiInputBase-root {
                  padding: 0px 14px;
                  & input {
                    max-width: 80% !important;
                    padding: 0;
                    margin: 0;
                    line-height: 32px;
                  }
                }
              }
            }
            & .search-filter:nth-child(3) .search-filter-component {
              width: 250px;
            }
          }
          & .search-button-container {
            position: relative;
            left: -150px;
            float: left;
            margin: 0;
            padding-left: 20px;
            & button {
              vertical-align: bottom;
              margin: 0 8px 0 0;
              width: 90px;
              height: 32px;
              background: #41506e;
              text-align: center;
              padding: 0 10px;
            }
          }
          & .search-button-container::before {
            content: "";
            position: absolute;
            margin-top: 6px;
            left: -6px;
            top: 5px;
            width: 1px;
            height: 24px;
            border-left: 1px #ddd solid;
          }
        }
      }
      & .button-container {
        float: right;
        margin: 7px 0 14px 0;
        & button {
          margin: 0 0 0 8px;
          min-width: 100px;
          height: 32px;
          background: #fff;
          text-align: center;
          padding: 0 12px;
          border: 1px #c4d5e1 solid;
          transition: all 0.3s ease-in-out;
        }
        & button:hover {
          color: #ffffff;
          background-color: #236daa;
        }
      }
    }
  }
  & .footer-container {
    margin: 0 0 32px 0;
  }
  & .MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
  }
  & .footer-margin-left {
    margin-left: -50px;
  }
}

#delivery-progress-modal-container {
  padding: 42px 34px 34px 34px;
  & .title-container {
    width: 100%;
    padding: 0 0 22px 0;
    margin: 0 0 25px 0;
    border-bottom: 1px #e6e6e6 solid;
    overflow: hidden;
    & span {
      display: block;
      font-size: 28px;
      color: #333;
      line-height: 1.1;
      text-align: left;
    }
    & .closed {
      position: absolute;
      right: 35px;
      top: 27px;
      z-index: 100;
      & a {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../imgs/close-button.png) left top no-repeat;
      }
      & a:hover {
        cursor: pointer;
      }
    }
  }
  .delivery-order-status-search-container {
    width: 100%;
    height: 121px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;

    & .search-filter-container {
      padding-left: 30px;
      padding-right: 45px;
      margin-top: 0;
      & .search-filter-presenter {
        width: 1200px;
        & .select-branch-component {
          width: 340px;
          height: 32px;
          margin-left: 10px;
        }
      }

      & .search-button-container {
        & button {
          width: 100px;
          height: 75px;
        }
      }
    }
  }

  .delivery-order-status-first-button-area {
    height: 46px;

    & button {
      height: 32px;
      padding: 0 12px;
      margin-left: 8px;
      float: right;
    }
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }
  .delivery-order-status-table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
  }
  & .footer-margin-left {
    margin-left: -50px;
  }
}
