#order-history-view {
  display: flex;
  flex-direction: column;
  & .content-wrapper {
    margin: 80px 0 0 0;
    height: fit-content;
    // 헤더 영역
    & .head-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
      position: relative;
      width: auto;
      min-height: 32px;
      margin-bottom: 14px;
      // 검색 영역
      & .search-container {
        display: flex;
        & input,
        & .MuiInputBase-root {
          font-weight: 500;
        }
        // 검색 조건 영역
        & .search-option-container {
          height: 32px;
          margin-right: 20px;
          display: flex;
          border-right: 1px #ddd solid;
          // 타이틀 영역 공통
          & .search-filter-title {
            height: 32px;
            & .search-filter-title-text {
              height: 32px;
              line-height: 32px;
              margin-right: 15px;
              text-align: left;
            }
          }
          // 데이터 영역 공통
          & .search-filter-component {
            & .MuiInputBase-root {
              height: 32px;
              & .MuiInputBase-input {
                line-height: 32px;
              }
            }
          }
          // 일자 선택 영역
          & .search-filter-date {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              display: flex;
              height: 32px;
              & .date-picker {
                width: 150px;
                height: 32px;
              }
              // ~
              & .range-text {
                width: 10px;
                margin: 0 10px;
                line-height: 32px;
              }
            }
          }
          // 수주등록구분 영역
          & .search-filter-select {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              text-align: left;
              & .select-options {
                width: 150px;
              }
            }
          }
          & .MuiList-root {
            & .MuiAutocomplete-listbox {
              border: 1px solid rgba(0, 0, 0, 0.23);
              overflow-x: hidden;
            }
          }
          // 도서명 영역
          & .search-filter-text {
            display: flex;
            margin-right: 20px;
            // 데이터 영역
            & .search-filter-component {
              & .search-book-async {
                width: 300px;
                & .MuiInputBase-root {
                  padding: 0px 14px;
                  height: 32px;
                  & input {
                    max-width: 80% !important;
                    padding: 0px;
                  }
                }
              }
            }
          }
        }
        // 조회 버튼 영역
        & .search-button-container {
          & .search-button {
            width: 76px;
            height: 32px;
            background: #41506e;
            box-shadow: none;
          }
        }
      }
      // 버튼 영역
      & .button-container {
        display: flex;
        float: right;
        & button {
          margin-left: 10px;
          min-width: 100px;
          height: 32px;
          color: #236daa;
          background: #ffffff;
          text-align: center;
          padding: 0 12px;
          border: 1px #c4d5e1 solid;
          transition: all 0.3s ease-in-out;
        }
        & button:hover {
          color: #ffffff;
          background-color: #236daa;
        }
      }
    }
  }

  // 테이블 영역 공통
  & .table-container {
    margin-bottom: 30px;
    & .MuiTableCell-root {
      font-size: 14px;
      font-weight: 500;
      &:first-of-type {
        width: 44px;
        text-align: center;
      }
    }
    & .MuiTableCell-alignRight {
      padding-right: 15px;
    }
  }

  // 상세조회 주문번호 영역
  & .detail-head-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    position: relative;
    width: auto;
    min-height: 32px;
    max-height: 32px;
    margin-bottom: 14px;
    line-height: 32px;
    & .order-info-container {
      display: flex;
      & .order-number {
        margin-right: 20px;
      }
    }
    & button {
      margin-left: 10px;
      min-width: 100px;
      height: 32px;
      color: #236daa;
      background: #ffffff;
      text-align: center;
      padding: 0 12px;
      border: 1px #c4d5e1 solid;
      transition: all 0.3s ease-in-out;
    }
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
    & .cancel-button {
      width: 123px;
      &:disabled {
        color: rgba(0, 0, 0, 0.26);
        border: 1px rgba(0, 0, 0, 0.12) solid;
      }
    }
  }

  // 상세조회 하단 영역
}

#cancel-confirm-modal-container {
  & .body-container {
    width: 280px;
    height: 150px;
    vertical-align: middle;
    & .confirm-text {
      height: 110px;
      padding-top: 40px;
      font-size: 18px;
    }
    & .button-container {
      & button {
        width: 105px;
      }
      // display: flex;
      & .order-cancel-button {
        margin-right: 5px;
      }
      & .no-button {
        margin-left: 5px;
      }
    }
  }
}
