#deals-ledger-view {
  & .content-wrapper {
    margin-bottom: 0px;
    & .content {
      & .deals-ledger-search-container {
        display: flex;
        justify-content: space-between;
        min-height: 32px;
        margin-bottom: 14px;
        & .search-filter-container {
          display: flex;
          width: auto;
          height: 32px;
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          & input {
            font-weight: 400;
          }
          .search-filter-presenter {
            width: auto;
            height: 32px;
            margin-right: 20px;
            border-right: 1px #ddd solid;
            display: flex;
            flex-wrap: nowrap;
            .search-filter {
              height: 32px;
              padding: 0;
              margin-right: 20px;
              flex-basis: auto;
              & .select-options {
                width: 160px;
              }
              // 타이틀 영역 공통
              & .search-filter-title {
                width: 80px;
                height: 32px;
                margin-left: 0;
                & .search-filter-title-text {
                  width: 70px;
                  height: 32px;
                  line-height: 32px;
                  margin-right: 10px;
                  text-align: left;
                }
              }
              // 데이터 영역 공통
              & .search-filter-component {
                width: auto;
                height: 32px;
                margin: 0;
                display: flex;
                & .date-picker {
                  width: 150px;
                  height: 32px;
                  margin: 0;
                }
                // ~
                & .range-text {
                  width: 10px;
                  margin: 0 10px;
                  line-height: 32px;
                }
                & .MuiInputBase-root {
                  height: 32px;
                  margin: 0;
                  & .MuiInputBase-input {
                    text-align: left;
                    line-height: 32px;
                  }
                }
              }
            }
          }
          .search-button-container {
            padding: 0;
            margin: 0;
            & .search-button {
              width: 76px;
              height: 32px;
              background: #41506e;
              box-shadow: none;
            }
          }
        }
        & .button-area {
          float: right;
          & button {
            margin: 0 0 0 10px;
            min-width: 100px;
            height: 32px;
            background: #fff;
            text-align: center;
            padding: 0 12px;
            border: 1px #c4d5e1 solid;
            transition: all 0.3s ease-in-out;
            & u {
              color: red;
            }
          }
          & button:hover {
            color: #ffffff;
            background-color: #236daa;
          }
        }
      }
      //
      & .deals-ledger-first-table-container {
        margin-bottom: 15px;
        & table {
          & .MuiTableCell-root {
            font-size: 14px;
            font-weight: 500;
          }
          // & th {
          //   & div {
          //     margin: auto;
          //     font-size: 14px;
          //     font-weight: 500;
          //     // & div > span {
          //     //   display: none;
          //     // }
          //     & .css-epvm6 {
          //       margin: 0;
          //       padding: 0;
          //     }
          //   }
          // }
          // & td {
          //   text-align: center;
          //   vertical-align: middle;
          //   font-size: 14px;
          //   font-weight: 500;
          // }
        }
      }

      // 계좌번호 영역
      & .account-info-container {
        font-size: 18px;
        & .info-row {
          display: flex;
          & .info-title {
            width: 70px;
            text-align: left;
          }
          & .info-m {
            width: 15px;
            text-align: left;
          }
        }
      }

      & .deals-ledger-second-table-container {
        margin-top: 15px;
        & .MuiTableCell-root {
          font-size: 14px;
          font-weight: 500;
          // 구분
          &:nth-of-type(3) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          // 지점
          &:nth-of-type(4) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          // 적요
          &:nth-of-type(5) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          // 비고
          &:nth-of-type(12) {
            & div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        & .MuiTableCell-alignRight {
          padding-right: 10px;
        }
      }
    }
  }
  & .footer-container {
    margin-top: 0px;
  }
}

#compare-modal-container {
  width: 580px;
  height: auto;
  & .innerBox {
    padding: 42px 34px 34px 34px;
    & #guHead {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 25px 0;
      border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & span {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      & .guClosed {
        float: right;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../../imgs/close-button.png) left top no-repeat;
          cursor: pointer;
        }
      }
    }
    & .tbList {
      vertical-align: middle;
      border-top: 2px #00182e solid;
      & table {
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        word-break: break-all;
        width: 100%;
        padding: 0 5px 0 0;
        & th {
          position: relative;
          height: 38px;
          background: #f7f7f7;
          border-bottom: 1px solid #e6e6e6;
          & span {
            display: inline-block;
            font-size: 12px;
            color: #333;
            line-height: 1.2;
            font-weight: normal;
            padding: 3px 0 0 0;
          }
        }
        & td {
          vertical-align: middle;
          font-size: 12px;
          color: #666;
          line-height: 1.2;
          border-bottom: 1px #e6e6e6 solid;
          word-break: break-all;

          & input[type="text"] {
            width: 100%;
            max-height: 32px;
            font-size: 12px;
            color: #333;
            line-height: 32px;
            background: #fff;
            padding: 0 0 0 10px;
            border: 1px #ccc solid;
            margin: 0;
            box-sizing: border-box;
            vertical-align: middle;
            margin: -5px 0 -4px;
          }
          & input[disabled] {
            background-color: #dedede;
            color: #333;
          }
          & textarea[disabled] {
            background-color: #dedede;
            color: #333;
          }
          & .memo {
            width: 100%;
            padding: 10px 0 0 0;
            margin: 0 0 -10px 0;
            overflow: hidden;
            & textarea {
              border: 1px solid #ccc;
              overflow-y: auto;
              resize: none;
              border-radius: 0;
              font-weight: 400;
              width: 100%;
              height: 80px;
              font-size: 12px;
              color: #333;
              line-height: 1.4;
              padding: 5px 10px;
            }
          }
          & .date-picker {
            height: 32px;
            width: 100%;
            & .MuiInputBase-root {
              height: 32px;
            }
          }
        }
        & .memos {
          padding-top: 0px;
          & .memo {
            padding-top: 5px;
          }
        }
        & .center {
          text-align: center !important;
          & .chk {
            position: relative;
            display: inline-block;
            padding: 5px 0;
          }
        }
        & .btn {
          position: relative;
          padding: 10px px 95px 9px 15px;
          & .blueBtn {
            border: none;
            box-shadow: none;
            border-radius: 0;
            overflow: visible;
            cursor: pointer;
            min-width: 76px;
            height: 32px;
            background: #41506e;
            text-align: center;
            margin: 0 0 0 10px;
            padding: 0 10px;
            position: absolute;
            right: 8px;
            top: 5px;
            & span {
              font-size: 12px;
              color: #fff;
              font-weight: 600;
              line-height: 34px;
            }
          }
          & .blueBtn:hover {
            background-color: #236daa;
            & span {
              color: #ffffff;
            }
          }
        }
      }
    }
    & .leftPop {
      text-align: left;
      & th {
        padding: 11px 15px 9px 15px;
        vertical-align: top;
      }
      & td {
        text-align: left;
        padding: 11px 10px 9px 15px;
      }
    }
    & .btnArea {
      position: relative;
      width: 100%;
      min-height: 32px;
      padding: 20px 0 0 0;
      text-align: center;
      & .cBtn {
        box-shadow: none;
        border-radius: 0;
        overflow: visible;
        cursor: pointer;
        min-width: 100px;
        height: 32px;
        background: #fff;
        text-align: center;
        margin: 0 8px;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        & span {
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
          line-height: 32px;
        }
      }
      & .cBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
      & .cBtn[disabled] {
        border: 1px solid #999999;
        background-color: #eee;
        & span {
          color: #555;
        }
      }
      & .cBtn[disabled]:hover {
        cursor: default;
      }
    }
  }
}
