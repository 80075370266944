#direct-delivery-order-search {
  & .direct-delivery-order-button-container {
    width: 1440px;
    display: flex;
    min-height: 32px;
    margin-bottom: 14px;
    & .order-date {
      display: flex;
      padding-right: 20px;
      & .title {
        line-height: 32px;
      }
      & .MuiFormControl-root {
        height: 32px;
        width: 120px;
        margin-left: 20px;
        & .MuiInputBase-root {
          height: 32px;
          background-color: #dedede;
          & input {
            -webkit-text-fill-color: black;
            font-size: 12px;
            margin-top: 4px;
          }
          & .MuiInputAdornment-root {
            display: none;
          }
        }
      }
    }
    & .upload-button-container {
      margin-left: 20px;
      & .upload-button {
        background-color: #41506e;
        height: 32px;
      }
    }
    & button {
      margin-left: 10px;
      width: 102px;
      font-size: 12px;
      height: 32px;
    }
    & button:first-of-type {
      margin-left: auto;
    }
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }
  & .MuiPaper-root {
    box-shadow: none !important;
    & .MuiTableContainer-root {
      & .MuiBox-root {
        box-shadow: none;
      }
      & table thead tr th.MuiTableCell-root {
        border-top: 2px #00182e solid;
        background-color: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
      }
      & tfoot {
        background-color: #f7f7f7;
      }
    }
  }

  & .MuiTableCell-head {
    &:first-of-type {
      width: 30px !important;
      padding-right: 4px;
      padding-left: 4px;
      & .MuiCheckbox-root {
        width: 20px;
        height: 20px;
      }
    }
  }

  & .MuiTableCell-root {
    font-size: 14px;
    font-weight: 500;
    &:first-of-type {
      width: 30px;
      padding-right: 4px;
      padding-left: 4px;
      text-align: center;
      & .MuiCheckbox-root {
        width: 20px;
        height: 20px;
      }
    }
    &.MuiTableCell-body {
      // 도서검색 input
      &:nth-child(5) {
        & input {
          font-size: 14px;
          font-weight: 500;
          max-width: 80% !important;
        }
      }
      // 저자
      &:nth-child(9) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      // 출판사
      &:nth-child(10) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      // 주문수량 input
      &:nth-child(14) {
        & input {
          margin-left: 5px;
          margin-right: 5px;
          text-align: right;
          font-size: 14px;
          font-weight: 500;
        }
      }
      // 출판사
      &:nth-child(16) {
        & div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      & .MuiAutocomplete-popper {
        width: 800px !important;
        border-radius: 4px;
        & .MuiPaper-root {
          & .option-list-item {
            width: 800px !important;
            & div {
              &:first-of-type {
                width: 120px;
              }
              &:nth-of-type(3) {
                width: 350px !important;
              }
              &:nth-of-type(5) {
                width: 80px !important;
              }
              &:nth-of-type(7) {
                width: 80px !important;
              }
              &:nth-of-type(9) {
                width: 80px !important;
              }
            }
          }
        }
      }
    }
  }
  & .MuiTableCell-alignRight {
    padding-right: 15px;
  }
  & .footer-margin-left {
    margin-left: -120px;
  }

  & .MuiAutocomplete-root {
    height: 30px;
    & .MuiFormControl-root {
      height: 30px;
      & .MuiInputBase-root {
        height: 30px;
        padding: 0;
        & input {
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }
  }

  // 테이블 하단 주문 정보 영역
  & .direct-delivery-order-info-container {
    justify-content: space-between;
    display: flex;
    & .title-text {
      font-size: 20px;
      font-weight: 500;
    }
    // 주문자 및 배송지 정보 영역
    & .direct-delivery-order-address-container {
      // float: left;
      text-align: left;
      margin-top: 10px;
    }
    // 결제금액 정보 영역
    & .direct-delivery-order-pay-info-container {
      // float: right;
      width: 200px;
      text-align: left;
      margin-top: 10px;
      & .info {
        display: flex;
        justify-content: space-between;
        & .sub-title-text {
          width: 90px;
          display: flex;
          text-align: justify;
        }
        & .amount-text {
          width: 135px;
          text-align: right;
        }
      }
    }
  }

  & .shipfee-info-container {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    text-align: left;
    font-size: 17px;
    & .shipfee-info-text {
      & span {
        font-size: 20px;
      }
    }
  }

  // 배송메세지 영역
  & .delivery-request-container {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    height: 32px;
    & .delivery-request {
      display: flex;
      & .delivery-request-text {
        // font-size: 20px;
        line-height: 32px;
        margin-right: 10px;
      }
      & .delivery-request-input {
        & .delivery-request-input-field {
          & input {
            height: 32px;
            width: 396px;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 500;
          }
        }
      }
    }
  }

  & .direct-delivery-order-bottom-button-container {
    display: flex;
    margin-top: 15px;
    & .account-selection.active {
      width: 450px;
      & .account-selection-select {
        width: 430px;
        height: 32px;
        margin-left: 10px;
        display: flex;
      }
      margin-right: -10px;
      transition: width 0.5s;
    }
    & .account-selection {
      width: 0;
      overflow: hidden;
      & .account-selection-select {
        width: 0px;
        height: 32px;
        margin-left: 10px;
        display: none;
        overflow: hidden;
      }
      margin-right: 0px;
      transition: width 0.5s;
    }
    & .misu-amt {
      margin-left: auto;
      height: 32px;
      display: flex;
      align-items: center;
      //vertical-align: middle;
      //text-align: center;
      //align-items: center;
    }
    & .payment-method {
      width: 200px;
      text-align: left;
      height: 32px;
      margin-left: 10px;
      font-weight: 600;
    }
    & button {
      margin-left: 10px;
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      height: 32px;
    }
    & button:hover {
      color: #ffffff;
      background-color: #236daa;
    }
  }
}

.direct-delivery-order-address-modal {
  width: 700px;
  height: auto;
  padding: 42px 34px 34px 34px;
  & .address-search {
    & .title-container {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 0 0;
      border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & .title {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      & .closed {
        position: absolute;
        right: 35px;
        top: 27px;
        z-index: 100;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../../imgs/close-button.png) left top no-repeat;
        }
        & a:hover {
          cursor: pointer;
        }
      }
    }
    & .body-container {
      text-align: left;
      vertical-align: middle;
      //border-top: 2px #00182e solid;
      & table {
        width: 100%;
        padding: 0 5px 0 0;
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        word-break: break-all;
        display: table;
        & tbody {
          & tr {
            & th {
              font-weight: 600;
              position: relative;
              height: 38px;
              background: #f7f7f7;
              border-bottom: 1px solid #e6e6e6;
              padding: 11px 15px 9px 15px;
              vertical-align: top;
              & span {
                display: inline-block;
                font-size: 12px;
                color: #333;
                line-height: 1.2;
                font-weight: normal;
                padding: 3px 0 0 0;
              }
            }
            & td {
              vertical-align: middle;
              font-size: 12px;
              color: #666;
              line-height: 1.2;
              border-bottom: 1px #e6e6e6 solid;
              word-break: break-all;
              padding: 11px 10px 9px 15px;
              & input[type="text"] {
                width: 100%;
                height: 32px;
                font-size: 12px;
                color: #333;
                line-height: 32px;
                background: #fff;
                padding: 0 0 0 10px;
                border: 1px #ccc solid;
                margin: -5px 0 -4px;
                box-sizing: border-box;
                vertical-align: middle;
              }
              & p {
                margin: 0;
              }
            }
            & td.center {
              text-align: center !important;
            }
            & td.btn {
              position: relative;
              padding: 10px 95px 9px 15px;
              & .blueBtn {
                position: absolute;
                right: 10px;
                top: 5px;
                border: none;
                box-shadow: none;
                border-radius: 0;
                overflow: visible;
                cursor: pointer;
                min-width: 76px;
                height: 32px;
                background: #41506e;
                text-align: center;
                margin: 0 0 0 10px;
                padding: 0 10px;
                & span {
                  font-size: 12px;
                  color: #fff;
                  font-weight: 600;
                  line-height: 34px;
                }
              }
              & .blueBtn:hover {
                background-color: #236daa;
                & span {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
    & .footer-container {
      position: relative;
      width: 100%;
      min-height: 32px;
      padding: 20px 0 0 0;
      text-align: center;
      margin: 0;
      min-width: 360px;
      & .cBtn {
        overflow: visible;
        cursor: pointer;
        box-shadow: none;
        border-radius: 0;
        min-width: 100px;
        height: 32px;
        background: #fff;
        text-align: center;
        margin: 0 8px;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        & span {
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
          line-height: 32px;
        }
      }
      & .cBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
    }
  }
  & .address-list {
    & .title-container {
      width: 100%;
      padding: 0 0 22px 0;
      margin: 0 0 0 0;
      //border-bottom: 1px #e6e6e6 solid;
      overflow: hidden;
      & .title {
        display: block;
        font-size: 28px;
        color: #333;
        line-height: 1.1;
        float: left;
      }
      & .closed {
        position: absolute;
        right: 35px;
        top: 27px;
        z-index: 100;
        & a {
          display: block;
          width: 40px;
          height: 40px;
          background: url(../../imgs/close-button.png) left top no-repeat;
        }
        & a:hover {
          cursor: pointer;
        }
      }
    }
    & .leftPop {
      vertical-align: middle;
      border-top: 2px #00182e solid;
      text-align: left;
      & table {
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        word-break: break-all;
        width: 100%;
        padding: 0 5px 0 0;
        & td {
          font-size: 12px;
          color: #666;
          line-height: 1.2;
          border-bottom: 1px #e6e6e6 solid;
          word-break: break-all;
          text-align: left;
        }
        & .center {
          position: relative;
          height: 38px;
          background: #f7f7f7;
          border-bottom: 1px solid #e6e6e6;
          padding: 11px 15px 9px 15px;
          vertical-align: top;
          text-align: center;
          & span {
            display: inline-block;
            font-size: 12px;
            color: #333;
            line-height: 1.2;
            font-weight: normal;
            padding: 3px 0 0 0;
          }
        }
        & .btn {
          position: relative;
          padding: 10px 95px 9px 15px;
          & input[type="text"] {
            width: 100%;
            height: 32px;
            font-size: 12px;
            color: #333;
            line-height: 32px;
            background: #fff;
            padding: 0 0 0 10px;
            border: 1px #ccc solid;
            box-sizing: border-box;
            vertical-align: middle;
            margin: -5px 0 -4px;
          }
          & .blueBtn {
            border: none;
            box-shadow: none;
            border-radius: 0;
            overflow: visible;
            cursor: pointer;
            min-width: 76px;
            height: 32px;
            background: #41506e;
            text-align: center;
            margin: 0 0 0 10px;
            padding: 0 10px;
            position: absolute;
            right: 10px;
            top: 11px;
          }
          & span {
            font-size: 12px;
            color: #fff;
            font-weight: 600;
            line-height: 34px;
          }
          & .blueBtn:hover {
            background-color: #236daa;
            & span {
              color: #ffffff;
            }
          }
        }
      }
    }
    & .mt50 {
      text-align: center;
      vertical-align: middle;
      border-top: 2px #00182e solid;
      margin: 50px 0 0 0;
      max-height: 360px;
      overflow-y: scroll;
      & table {
        border-collapse: collapse;
        border-spacing: 0;
        table-layout: fixed;
        word-break: break-all;
        width: 100%;
        padding: 0 5px 0 0;
      }
      & th {
        vertical-align: middle;
        position: relative;
        height: 38px;
        background: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
        & span {
          display: inline-block;
          font-size: 12px;
          color: #333;
          line-height: 1.2;
          font-weight: normal;
          padding: 3px 0 0 0;
        }
      }
      & td {
        vertical-align: middle;
        font-size: 12px;
        color: #666;
        line-height: 1.2;
        border-bottom: 1px #e6e6e6 solid;
        padding: 11px 5px 9px;
        word-break: break-all;
        & .deleteBtn {
          border: 1px #c4d5e1 solid;
          color: #236daa;
          background: none;
          display: inline-block;
          *display: inline;
          cursor: pointer;
        }
        & .deleteBtn:after {
          display: inline-block;
          content: "\00d7";
          font-size: 10pt;
          font-weight: 600;
        }
      }
      & .miniLeft {
        text-align: left;
        padding: 11px 5px 9px 5px;
      }
      & .mBtn {
        box-shadow: none;
        border-radius: 0;
        padding: 0;
        overflow: visible;
        cursor: pointer;
        width: 50px;
        height: 25px;
        background: #fff;
        text-align: center;
        border: 1px #c4d5e1 solid;
        & span {
          line-height: 25px;
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
        }
      }
      & .mBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
    }
    & .btnArea {
      position: relative;
      width: 100%;
      min-height: 32px;
      padding: 20px 0 0 0;
      text-align: center;
      & .cBtn {
        box-shadow: none;
        border-radius: 0;
        overflow: visible;
        cursor: pointer;
        min-width: 100px;
        height: 32px;
        background: #fff;
        text-align: center;
        margin: 0 8px;
        padding: 0 12px;
        border: 1px #c4d5e1 solid;
        & span {
          font-size: 14px;
          color: #236daa;
          font-weight: 600;
          line-height: 32px;
        }
      }
      & .cBtn:hover {
        background-color: #236daa;
        & span {
          color: #ffffff;
        }
      }
    }
  }
}

.direct-delivery-order-request-modal {
  width: 400px;
  height: 230px;
  padding: 22px 14px 14px 14px;
  & .title-container {
    width: 100%;
    padding: 0 0 22px 0;
    margin: 0 0 0 0;
    border-bottom: 1px solid #e6e6e6;
    overflow: hidden;
    & .title {
      display: block;
      font-size: 28px;
      color: #333;
      line-height: 1.1;
      float: left;
    }
    & .closed {
      position: absolute;
      right: 35px;
      top: 27px;
      z-index: 100;
      & a {
        display: block;
        width: 40px;
        height: 40px;
        background: url(../../imgs/close-button.png) left top no-repeat;
      }
      & a:hover {
        cursor: pointer;
      }
    }
  }
  & .message-container {
    vertical-align: middle;
    margin-top: 20px;
  }

  & .btnArea {
    position: relative;
    width: 100%;
    min-height: 32px;
    padding: 30px 0 0 0;
    text-align: center;
    & .cBtn {
      box-shadow: none;
      border-radius: 0;
      overflow: visible;
      cursor: pointer;
      min-width: 100px;
      height: 32px;
      background: #fff;
      text-align: center;
      margin: 0 8px;
      padding: 0 12px;
      border: 1px #c4d5e1 solid;
      & span {
        font-size: 14px;
        color: #236daa;
        font-weight: 600;
        line-height: 32px;
      }
    }
    & .cBtn:hover {
      background-color: #236daa;
      & span {
        color: #ffffff;
      }
    }
  }
}
